import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import IceInfoParagraph from "../components/ice-info-paragraph/ice-info-paragraph.js";
import { sitemap } from "../utils/information.js";


const Sitemap = () => {


  return (
    <Layout
      SEOTitle={"Sitemap - ICE"}
      SEODescription={"Sitemap - ICE"}
      footerDisclaimer={""}>

      <IceInfoParagraph props={sitemap}  main={true} />

    </Layout>
  )

}

export default Sitemap